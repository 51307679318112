import { usePrescriptionStore } from '~/stores/prescription';

export function useBankIdSessionExpiration() {
  if (process.server) {
    return {
      showInactiveWarning: false,
      cancelIdleWarning: () => {},
    };
  }
  const prescriptionStore = usePrescriptionStore();

  const IDLE_EVENTS = [
    'mousemove',
    'mousedown',
    'resize',
    'keydown',
    'touchstart',
    'wheel',
    'klarnaInteraction', // Custom event for Klarna iframe interaction
  ] as const;

  const ALLOWED_IDLE_TIME = 10 * 60;
  const IDLE_WARNING_TIME = ALLOWED_IDLE_TIME - 60;

  const checkLoggedInIntervalId = ref(0);
  const idleTimeoutId = ref(0); // stores the id of the current active timeout
  const idleTimerIntervalId = ref(0);
  const showInactiveWarningIntervalId = ref(0);

  const shouldShowInactiveWarning = ref(false);
  const idleTimeInSeconds = ref(0); // stores the time the user has been idle in seconds
  const timeToShowModalBeforeLoggingOutInSeconds = ref(60);

  // starts an interval that increments the idle time every second
  const startCountingIdleTime = () => {
    idleTimerIntervalId.value = window.setInterval(() => {
      idleTimeInSeconds.value += 1;
    }, 1000);
  };

  // every time this is called a new timeout is created
  const setIdleTimeout = () => {
    // if there's an idle timeout, it clears it
    if (idleTimeoutId.value) {
      window.clearTimeout(idleTimeoutId.value);
    }
    // sets a new timeout, that after the set idle time will logoutAndClearTimeout
    idleTimeoutId.value = window.setTimeout(() => {
      logoutAndClearTimeout();
    }, ALLOWED_IDLE_TIME * 1000);
    // extends cookie validity
    prescriptionStore.extendFVLogin10Minutes();
    // sets the idle timer to 0
    resetIdleTimer();
  };

  const setIdleTimeoutOnVisibilityChange = () => {
    if (!window.document.hidden) {
      setIdleTimeout();
    }
  };

  const logoutAndClearTimeout = () => {
    removeIdleEventListeners();
    window.clearTimeout(idleTimeoutId.value);
    prescriptionStore.logout();
  };

  const resetIdleTimer = () => {
    idleTimeInSeconds.value = 0;
  };

  const removeIdleEventListeners = () => {
    IDLE_EVENTS.forEach((event) => {
      window.removeEventListener(event, setIdleTimeout);
    });

    window.document.removeEventListener(
      'visibilitychange',
      setIdleTimeoutOnVisibilityChange
    );
  };

  const startIdleTimeout = () => {
    setIdleTimeout();

    IDLE_EVENTS.forEach((event) => {
      window.addEventListener(event, setIdleTimeout, { passive: true });
    });

    window.document.addEventListener(
      'visibilitychange',
      setIdleTimeoutOnVisibilityChange
    );
  };

  const cancelIdleWarning = () => {
    shouldShowInactiveWarning.value = false;
    timeToShowModalBeforeLoggingOutInSeconds.value = 60;
    window.clearInterval(showInactiveWarningIntervalId.value);
    setIdleTimeout();
  };

  // when the user is logged in or out, we logout or start the idle timer
  watch(
    () => prescriptionStore.isLoggedInToEcom2,
    (newValue: boolean | null, oldValue: boolean | null) => {
      if (newValue === false && oldValue === true) {
        prescriptionStore.logout();
      } else if (newValue === true && !oldValue) {
        startCountingIdleTime(); // starts counting the seconds the user is idle
        startIdleTimeout(); // sets the timeouts for all events
      }
    }
  );

  // based on the idle time decides when to show the warning modal or logout
  watch(idleTimeInSeconds, (newValue: number) => {
    if (newValue >= IDLE_WARNING_TIME && !shouldShowInactiveWarning.value) {
      shouldShowInactiveWarning.value = true;
      showInactiveWarningIntervalId.value = window.setInterval(() => {
        if (timeToShowModalBeforeLoggingOutInSeconds.value !== 0) {
          timeToShowModalBeforeLoggingOutInSeconds.value -= 1;
        }
        if (timeToShowModalBeforeLoggingOutInSeconds.value === 0) {
          logoutAndClearTimeout();
        }
      }, 1000);
    }
  });

  onMounted(() => {
    if (prescriptionStore.isLoggedInToEcom2) {
      startCountingIdleTime();
      startIdleTimeout();
    }

    checkLoggedInIntervalId.value = window.setInterval(() => {
      if (prescriptionStore.isLoggedInToEcom2) {
        prescriptionStore.updateIsLoggedIn();
      }
    }, 30000);
  });

  onUnmounted(() => {
    window.clearInterval(checkLoggedInIntervalId.value);
    window.clearInterval(showInactiveWarningIntervalId.value);
    window.clearInterval(idleTimerIntervalId.value);
    removeIdleEventListeners();
    window.clearTimeout(idleTimeoutId.value);
  });

  return {
    inactiveWarningMinutes: IDLE_WARNING_TIME / 60,
    idleTimeLeft: timeToShowModalBeforeLoggingOutInSeconds,
    showInactiveWarning: shouldShowInactiveWarning,
    cancelIdleWarning,
  };
}
